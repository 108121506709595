(() => {
  mapboxgl.accessToken =
    'pk.eyJ1Ijoia2F2YXRhbW9sb2tvIiwiYSI6ImNrMTZobzMzdjA4dnkzYm1yOWlldWdqa3AifQ.DUftgvW-rhKRtvQ6fHapdQ';
  const mapContainer = document.querySelector('.map-container');

  // colors can be
  // -------------
  // black
  // yellow
  // blue
  // purple
  // rose
  // -------------


  const coordsObj = [{
    coord: [30.379513, 50.422253],
    title: "бул. Кольцова, 14Л",
    image: "images/4b-place.png",
    color: "yellow"
  }, {
    coord: [30.494695, 50.39408],
    title: "вул. Васильківська, 23/16",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.445861, 50.469848],
    title: "вул. Дорогожицька, 13",
    image: "images/4b-place.png",
    color: "yellow"
  }, {
    coord: [30.395405, 50.415227],
    title: "вул. Жолудєва, 4",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.626521, 50.409053],
    title: "просп. Петра Григоренка, 22/20",
    image: "images/4b-place.png",
    color: "blue"
  }, {
    coord: [30.508729, 50.396798],
    title: "вул. Васильківська, 1",
    image: "images/4b-place.png",
    color: "blue"
  }, {
    coord: [30.453559, 50.426521],
    title: "вул. Донецька, 14/30",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.517104, 50.427543],
    title: "вул. Велика Васильківська, 73",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.480439, 50.383131],
    title: "просп. Голосіївський, 128",
    image: "images/4b-place.png",
    color: "rose"
  }, {
    coord: [30.489304, 50.476209],
    title: "вул. Кирилівська, 80",
    image: "images/4b-place.png",
    color: "rose"
  }, {
    coord: [30.540766, 50.417977],
    title: "вул. Іоанна Павла II, 23/35",
    image: "images/4b-place.png",
    color: "yellow"
  }, {
    coord: [30.487427, 50.455342],
    title: "вул. Дмитрівська, 71",
    image: "images/4b-place.png",
    color: "rose"
  }, {
    coord: [30.501186, 50.487880],
    title: "просп. Степана Бандери, 16Б",
    image: "images/4b-place.png",
    color: "blue"
  }, {
    coord: [30.4056780, 50.4805117],
    title: "вул. Данила Щербаківського, 63",
    image: "images/4b-place.png",
    color: "blue"
  }, {
    coord: [30.4429302, 50.4402135],
    title: "вул. Вадима Гетьмана, 40 А",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.4749584, 50.4847124],
    title: "вул. Кирилівська, 126/2",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.483979, 50.439556],
    title: "Залізничний вокзал «Південний»",
    image: "images/4b-place.png",
    color: "purple"
  }, {
      coord: [30.409236, 50.472176],
      title: 'вул. Салютна, 17',
      image: 'images/4b-place.png',
      color: 'yellow'
    },{
      coord: [30.429798, 50.503628],
      title: 'просп. Правди, 35',
      image: 'images/4b-place.png',
      color: 'yellow'
    }, {
      coord: [30.516128, 50.431046],
      title: 'вул. Велика Васильківська, 63',
      image: 'images/4b-place.png',
      color: 'yellow'
    }, {
      coord: [30.486603, 50.453443],
      title: 'вул. Золотоустівська, 48/5',
      image: 'images/4b-place.png',
      color: 'yellow'
    }, {
      coord: [30.544690, 50.411465],
    title: "вул. Михайла Драгомирова, 4Б",
    image: "images/4b-place.png",
    color: "rose"
   }, {
    coord: [30.472248, 50.467309],
    title: "вул. Юрія Іллєнка, 75",
    image: "images/4b-place.png",
    color: "blue"
  }, {
    coord: [30.459583, 50.436342],
    title: "вул. Єреванська, 14",
    image: "images/4b-place.png",
    color: "blue"
  }, {
    coord: [30.599439, 50.397119],
    title: "вул. Осокорська, 2А",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.495764, 50.388655],
    title: "просп. Голосіївський, 104",
    image: "images/4b-place.png",
    color: "purple"
  }, {
    coord: [30.449290, 50.377856],
    title: "вул. Лятошинського, 14",
    image: "images/4b-place.png",
    color: "rose"
  }
  ];



  let zoomNumber = null;

  const resizeblZoom = () => {
    if ( window.outerWidth <= 767 ) {
      zoomNumber = 9;
    } else {
      zoomNumber = 11;
    }

    initMap(zoomNumber);
  };

  window.onload = () => {
    resizeblZoom();
  };

  // window.addEventListener("resize", function() {
  //   resizeblZoom();
  // });

  const initMap = zoomVal => {

    const map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/light-v9",
      center: [30.5083542,50.4362128],
      zoom: zoomVal
    });

    coordsObj.forEach(el => {
      const div = document.createElement("div");
      const content = document.createElement("div");
      const image = document.createElement("img");
      const info = document.createElement("div");
      const infoText = document.createElement("p");

      div.className = `map-pin map-pin--${el.color}`;
      content.className = "map-pin__content";
      image.className = "map-pin__content-image";
      image.src = `${el.image}`;
      info.className = "map-pin__info";
      infoText.className = "map-pin__info-text";
      infoText.innerHTML = el.title;

      content.appendChild(image);
      div.appendChild(content);
      info.appendChild(infoText);
      div.appendChild(info);

      new mapboxgl.Marker(div)
        .setLngLat(el.coord)
        .addTo(map);
    });
  }

})();
