(() => {

  const modalButtons = [...document.querySelectorAll('[data-modal-btn]')];
  const modalWrap = document.querySelector('.modal-wrap');
  const modalWindows = [...document.querySelectorAll('[data-modal]')];
  const modalCloseButtons = document.querySelectorAll('.modal-window__close-btn');

  modalButtons.forEach(btn => {
    btn.addEventListener("click", evt => {
      evt.preventDefault();
      findModal(btn);
    });
  });

  modalCloseButtons.forEach(btn => {
    btn.addEventListener("click", evt => {
      evt.preventDefault();
      closeModals();
    });
  });


  const findModal = btn => {
    const modal = modalWindows.find(el => {
      return el.getAttribute("data-modal") === btn.getAttribute("data-modal-btn");
    });

    showModal(modal);
  };

  const showModal = el => {
    modalWrap.classList.add('modal-wrap--open');
    el.classList.add('modal-window--open');
  };

  const closeModals = () => {
    modalWrap.classList.remove('modal-wrap--open');
    modalWindows.forEach(el => {
      el.classList.remove('modal-window--open');
    });
  };

})();
